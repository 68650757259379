*{margin: 0;padding: 0;}
body{
	color: #313131 !important;
}
.img-banner{
	width: 100%;
}
.ketoan h3{
	padding-top: 40px;
}
.ketoan .card-header{
	border-bottom: none;
}
.card-header-tt{
	background: #fff !important;
}
.card-body-tt{
	background: #f8f8f8;
	border:0.5px solid rgba(0,0,0,.125);
}
.ketoan .card-header a{
	font-size: 18px;
}
.ketoan a{
	color: #333 !important;
}
.b1{
	border: 1px solid #c4c4c4;
	border-radius: 60px;
}
.favicon-down{
	 padding-right: 10px;
	 padding-top: 10px;
	 text-align: center;
}
.dstaikhoan{
	width: 100%;
	padding: 20px;
}
.luuy1, .luuy2{
	padding: 0px !important;
	width: 100%;
}
.luuy1 img, .luuy2 img{
	padding: 0px !important;
	width: 100%;
}

.img-qr{
	max-width: 400px;
	width: 100%;
}
.line{
	width: 4px;
	background: #00aeef;
	height: 26px;
	margin-top: 11px;
}
.imgqr img{
	width: 100%;
}
