* {
    margin: 0;
    padding: 0;
}
body {
    height: 100%;

    /* background-image: url("../images/livestreams/bg.png"); */
    background-repeat: no-repeat;
    background-size: cover;

    font-family: "Arial";

    font-size: 14px;

    color: #333333;
}
.section {
    overflow: hidden;
    position: relative;
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 100vh;
}
.section.full-width > .section_wrapper {
    height: 100vh;
    padding: 20px !important;
}
.main-left {
    width: 75%;
    height: 100%;
    float: left;
}
.main-right {
    width: 25%;
    height: 100%;
    float: left;
}

/*CSS LEFT*/
.ct-left {
    padding-right: 20px;
}
.d-flex {
    /*display: flex;*/
}

.d-header {
    width: 85%;
    position: relative;
}
.img-fluid {
    padding-top: 6px;
}
.img-bg,
.ct-d-header {
    position: absolute;
}
.img-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}
.ct-d-header {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
}

.header {
    width: 100%;
    margin-bottom: 15px;
}
.tl-program {
    padding-left: 20px;
}
.main-tl {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}
.user {
    font-size: 13px;
    color: #fff;
}
.video {
    width: 100%;
    min-height: 420px;
    background: black;
    border-radius: 9px;
    border: 5px solid #1d69bb;
}
.ct-video {
    width: 100%;
    height: 56.25%;
    background: black;
    text-align: center;
}
.logo-dxmb {
    width: 50%;
    padding-top: 7px;
}

.emo-r {
    width: 50%;
}

.emo {
    text-align: right;
    background-image: url("../images/livestreams/bg-emo.png");
    background-repeat: no-repeat;
    height: 55px;
    background-size: contain;
    background-position: right;
}
.icon-emo {
    padding-right: 70px;
    padding-top: 10px;
}
.emo a {
    padding: 0 5px;
}

/*CSS RIGHT*/

.comments {
    height: 100%;
    width: 100%;
    background: #ffffff29;
    border-radius: 9px;
    color: #ffffffd4;
    line-height: 1.15;
    padding-top: 10px;
    position: relative;
}
.nav-item a {
    color: #fff9;
    padding: 10px 10px;
}
.nav-tabs .nav-link:hover {
    border: none !important;
    color: #fff !important;
}
.nav-item a.active {
    color: white !important;
    background: none !important;
    border: none !important;
    font-weight: bold !important;
}
.nav-tabs {
    border-bottom: 1px solid #fdfeff70 !important;
}
.number {
    background: #00000030;
    padding: 0 3px;
    border-radius: 8px;
}
.ct-comment {
    height: 67vh;
    overflow-y: scroll;
}
.img-avata {
    width: 35px;
    height: 35px;
    background-image: url("../images/livestreams/avata.jpg");
    border-radius: 50%;
    background-size: cover;
}
.user-time {
    font-size: 13px;
    color: #74c5ff;
}

.logo {
    width: 60%;
    height: auto;
}

.even {
    padding: 10px;
}
.bg-cm {
    background: #ffffff14;
}
.write-cm {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #fdfeff70 !important;
    margin-top: 20px;
    padding: 15px;
}
.write-cm input {
    color: #fff;
}
.other {
    padding-top: 16px;
}
